.member-certificate .certificate-main {
  width: 900px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  color: #837f7f;
  font-size: 13px;
  box-shadow: unset;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.32);
}
.member-cert-date{
  position: absolute;
  top: 132px;
  right: 84px;
  font-size: 12px;
  color: #494949;
}
.member-origin-cert {
  text-align: center;
}

.member-origin-cert p {
  margin-top: 57px;
  margin-bottom: -3px;
  color: rgb(79 79 221);
}

.member-certificate .numOf {
  position: absolute;
  top: 128px;
  right: 232px;
  font-size: 16px;
}

.pending-status {
  padding: 20px;
}

.pending-status>i {
  font-size: 24px;
  color: red;
  padding-right: 10px;
}

.member-certificate .certificate-content {
  position: absolute;
  text-align: center;
  top: 40%;
}

.cert-main {
  text-align: center;
}

.member-certificate .group-name {
  display: flex;
  position: relative;
  width: 866px;
  margin: 0 auto;
  text-align: center;
}

.member-certificate .group-name div {
  width: 50%;
  /* height: 120px;
  overflow: hidden; */
}

.member-certificate .group-name .name {
  font-weight: bold;
  font-size: 25px;
  padding: 30px;
  margin-right: 40px;
  color: #6d6d6d;
}

.member-certificate .image-cont {
  margin-left: 40%;
  width: 122px !important;
  height: 132px;
}

.member-certificate .image-cont span {
  position: relative;
  top: 43%;

}

.member-certificate .image img {
  width: 120px;
  /* height: 130px; */
  top: 0px;
  position: relative;
  opacity: 0.6;

}