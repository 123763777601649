.kyrs-item {
    font-size: 20px;
    padding: 5px 20px;
    margin: 20px 1px;
    border: 1px solid #8ca799;
    background: #e0fff5;
}
.payment-form{
    text-align: right;
}
.hidden{
    visibility: hidden;
}
.d-block{
    display: block;
}

.kyrs-item>a {
    color: black;
}

.kyrs-item>a:hover {
    color: #1266f1;
}

.my-table th {
    text-align: center;
    font-weight: bold;
}

.my-table>:not(caption)>*>* {
    padding: 10px;
    vertical-align: middle;
}

.kyrs-info h5 {
    font-weight: bold;
}
.kyrs-table{
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
}
.kyrs-table td{
    padding: 10px;
    
}
.kyrs-table th{
    vertical-align: middle  !important;
    font-weight: bold;
}

/* ______________________________ test_________________ */
.mini-form-modal .form-outline .form-control{
    border: 1px solid #e1e1e1;
}
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* background-color: rgb(0,0,0);  */
    background-color: rgb(0 0 0 / 58%);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    top: 30%;
    width: 80%;
}

.close {
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: rgb(255, 255, 255);
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 20px 16px;
    background-color: #d5d5d5;
}

.modal-body {
    padding: 2px 16px;
    padding-bottom: 15px;
}

.modal-body>p{
    padding: 10px 20px;
    border-bottom: 1px solid #f3f3f3;
    font-weight: bold;
    text-align: center;
    font-size: 19px;
}

.modal-body .answer-item{
    padding: 5px 13px;
}

.modal-body .answer-item:hover{
    background-color: #e9e9e9;
    cursor: pointer;
}

.modal-footer {
    padding: 2px 16px;
    background-color: #d5d5d5;
}

.modal-footer .btn-primary {
    background-color: #1266f1 !important;
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animatetop;
    animation-duration: 0.2s;
}

.anw-selected{
    color: #0074ff;
}

/* Add Animation */ 
@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }
    
    to {
        top: 30%;
        opacity: 1
    }
}


.certificate-mini{
    /* width: 100px; */
    /* width: 500px; */
    /* height: 200px; */
   
}
/* -------------------------------------- */

@media screen and (max-width: 576px) {
    .my-table {
        font-size: .6rem;
    }

    .my-table>:not(caption)>*>* {
        padding: 5px;
    }

    h5 {
        font-size: 14px;
    }

    .kyrs-info {
        font-size: 12px;
    }
}