.modal-add-news {
    z-index: 20;
}

.link-item{
    border: 1px solid #e5e5e5;
    margin: 10px 0;
    padding: 10px;
}

.modal-add-news .modal-content {
    width: 95%;
    height: 90%;
    top: 55px;
    overflow-y: auto;
}

.modal-add-news .modal-header {
    display: block;
    font-size: 20px;
}

.delete-img {
    padding: 10px;
    cursor: pointer;
}
.delete-img:hover i{
    color: blue;
}

.modal-body-btn {
    display: flex;
    align-items: center;
    text-align: center;
}

.modal-add-news .modal-body button {
    display: flex;
    margin: 10px;
    align-items: center;
}

.modal-add-news .modal-body button>i {
    font-size: 18px;
    padding-right: 10px;
}

.modal-body-img {
    text-align: center;
    padding-bottom: 20px;
}

.img-link {
    padding: 10px 0px;
    text-align: center;
    width: 100%;
    height: 100%;
}

.img-link>img {
    max-width: 800px;
    width: 100%;
    height: 100%;
}

#preview img {
    max-width: 800px;
}