a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.list-emails{
    overflow: hidden;
    font-size: 13px;
}

#small-table tr {
    font-size: 12px;
    border-bottom: 1px solid #e7e7e7;
}

#small-table tr:hover {
    cursor: pointer;
    background-color: #f9f9f9;
}

#small-table {
    width: 100%;
}

.s-title{
    color: #ababab;
    font-size: 13px;
}
.align-top{
    text-align: start;
}
/* ------------ Sidenav CSS ------------- */
#sidenav {
    position: fixed;
    overflow: auto;
    width: 300px;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background: #101010;
    transition-duration: .3s;
}

#sidenav.open {
    left: -300px;
    transition-duration: .1s;
}

#sidenav .logo .log-out {
    position: absolute;
    top: 2px;
    left: 10px;
    cursor: pointer;

}

#sidenav .logo {
    width: 100%;
    padding: 10px;
    background: #101010;
    /* display: flex; */
    text-align: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.08);
}

#sidenav .logo img {
    width: 90px;
}

#sidenav .logo a.nav-icon {
    display: none;
}

#sidenav .menu {
    position: static;
}

#sidenav .menu ul {
    list-style: none;
    background: #101010;
    padding: 5% 0;
    margin: 0;
}

#sidenav .menu ul li {
    padding: 6px 3% 6px 10%;
}

#sidenav .menu ul li div.active {
    border-left: solid 4px #0d2fa8;
}

#sidenav .menu ul li div:hover a {
    color: #ffffff;
}

#sidenav .menu ul li div.active a,
#sidenav .menu ul li div.active a i {
    color: #ffffff;
    cursor: pointer;
}

#sidenav .menu ul li div a {
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#sidenav .menu ul li div a i {
    font-size: 14px;
    padding-right: 13px;
    color: #676767;
}

#sidenav .menu ul li div {
    font-size: 21px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    display: block;
    padding-left: 20px;
    margin-left: -20px;
}

#sidenav .menu ul li div:hover {
    color: rgba(255, 255, 255, 0.5);
}

#sidenav .menu ul li div.logout:hover {
    color: red;
}

#sidenav .menu ul li ul {
    list-style: none;
    margin: 10px auto 0;
    padding: 10px 15px;
    border-top: solid 1px rgba(255, 255, 255, 0.08);
    border-bottom: solid 1px rgba(255, 255, 255, 0.08);
}

#sidenav .menu ul li ul li {
    padding: 6px 3px;
}

#sidenav .menu ul li ul li div {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
}


#dash-content {
    position: fixed;
    top: 30px;
    left: 300px;
    bottom: 0;
    right: 0;
    z-index: 4;
    transition-duration: 0.3s;
    box-shadow: inset 4px 0 0 #EFEFEF;
    overflow: auto;
}

.dashboard {
    margin-top: 100px;
}

.apll-table {
    vertical-align: middle;
}

.status-td {
    text-align: center;
}

.status-td i {
    font-size: 20px;
}


.photo-view {
    height: 106px;
    position: relative;

}

.photo-view img {
    width: 100px;
    z-index: 10;
}

.photo-view img:hover {
    position: absolute;
    top: -50%;
    right: -50%;
    width: unset;
    max-width: 700px;
}

.edit-icon {
    cursor: pointer;
    position: relative;

}

.edit-icon:hover .edit-body {
    display: block;
}

.edit-body {
    display: none;
    border: 1px solid rgb(133, 133, 133);
    width: 140px;
    position: absolute;

    top: 0;
    right: 5px;
    z-index: 9;
    background-color: rgb(89 89 89);
    -webkit-box-shadow: -7px 6px 28px -8px rgba(255, 255, 255, 1);
    -moz-box-shadow: -7px 6px 28px -8px rgba(255, 255, 255, 1);
    box-shadow: -7px 6px 28px -8px rgba(255, 255, 255, 1);
}

.edit-body>div {
    padding: 5px 10px;
    border-bottom: 1px solid rgb(184, 184, 184);
    color: #a9a9a9;
}

.edit-body>div:hover {
    color: white;
}


.edit-body .yellow,
.status-td .yellow {
    color: #e1e19b;
}

.edit-body>div i,
.status-td i {
    color: #9393ff;
    padding-right: 10px;
}

.edit-body>div .red,
.status-td .red {
    color: red;
}

.elips-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    height: 134px;
}

.elips-text:hover {
    overflow: auto;
    /* text-overflow: clip; */
    height: auto;
    min-height: 134px;
    /* background-color: #101010; */
    /* position: absolute;
    height: 90%;
    width: 90%;
    top: 5% ;
    left: 5%;
    font-size: 18px; */
    z-index: 999;
}

@media (min-width: 768px) {
    .nav-icon {
        display: none;
    }
}

@media (max-width: 768px) {
    #dash-content {
        left: 0;
        top: 60px;
        box-shadow: none;
    }

    #sidenav {
        width: 100%;
        height: 60px;
        background: none;
        /* transition-duration: .3s; */
    }

    #sidenav .logo {
        padding: 10px 20px;
    }

    #sidenav .logo a {
        font-size: 20px;
    }

    #sidenav .logo a.nav-icon {
        display: inline;
    }

    #sidenav .menu {
        display: none;
    }

    #sidenav .menu.open {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        display: block !important;
    }

    #sidenav .menu ul {
        border-top: solid 1px #333;
    }

    #sidenav .menu ul li {
        padding: 6px 5%;
    }
}