.modal-body {
    font-size: 18px;
}
.profile{
    min-height: 1000px;
}
.profile .nav-tabs{
    border-bottom: 1px solid #efefef;
}

.profile .nav-tabs .nav-item{
    cursor: pointer;
}

.profile .payments{
    min-height: 60vh;
}

.input-group-text {
    min-width: 145px;
    border-right: none;
    padding-top: none;
    padding-bottom: none;
    line-height: 1;
    font-size: 13px;
    font-weight: bold;
    justify-content: space-around;
    display: flex;
    color: gray;
}

.move-icon {
    display: none;
    position: absolute;
    color: #e1e1e1;
    margin-top: 22px;
    font-size: 9px;
    margin-left: 5px;
    cursor: ns-resize;
}

.accordion-header>button {
    font-weight: bold !important;
    font-size: 18px;
}

.accordion-item:hover .move-icon {
    display: block;
}

.accordion-button {
    padding: 0.5rem 1rem;
}

.accordion-button:after {
    margin-right: 20px;
}

.accordion-button:not(.collapsed) {
    padding-bottom: 20px;
}

.invalid-input {
    color: red !important;
    border: 1px solid red;
}

.item-category {
    padding: 5px;
    border: 1px solid transparent;
    transition: .5s;
    position: relative;
}

.edit-btns {
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 88;
    display: flex;
    display: none;
    width: 33px;
    height: 58px;
   
}

.edit-btns>i {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 6px;
    color: #868282;
}

.edit-btns>i:hover {
    color: #0057e2;

}

.item-category {
    position: relative;
    /* display: flex;
    justify-content: space-around; */
}

.item-category .edit-btns {
    right: -10px;
    top: -8px;
}

.del-icon {
    top: 32px !important;
    right: 8px !important;
}

.accordion-item {
    position: relative;
}

.accordion-item:hover>.edit-btns {
    display: block;
}

.item-category:hover>.edit-btns {
    display: block;
}

.profile .item-category:hover {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
}

.ava {
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.ava i {
    color: #d6d6d6;
}

.ava span{
    height: 100%;
    display: flex;
    align-items: center;
}

.ava:hover span{
    cursor: pointer;
    border: 1px solid #eaeaea;
}

.content h3,
h4 {
    border-bottom: 1px solid #ebebeb;
}

.add-button {
    text-align: right;
    margin-bottom: 20px;
}

.add-button i {
    padding-right: 5px;
    font-size: 14px;
}

.under-line {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.cat-group-btns {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.cat-group-btns>button {
    min-width: 180px;
    margin: 5px;
}

.user-info {
    text-align: center;
    align-items: center;
    font-weight: bold;
}

.user-info span{
    font-weight: normal;
    color: #8a8a8a;
    border-bottom: 1px solid #ececec;
    padding: 5px;
}

.user-info h4 {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .cat-group-btns {
        justify-content: space-around;
    }
}

.cat-header{
    text-align: center;
    font-weight: bold;
}

.profile-cat .cat-item {
    border: 1px solid #e7e7e7;
    margin-top: 3px;
    padding: 0 5px;
    cursor: pointer;
    background: #dde3e7;
    
}
.profile-cat .cat-item:hover{
    /* color: #0057e2; */
    background: #b1bec7;
}
.profile-cat .active{
    color: #ffffff;
    background: #64737d;
}