:root {
  --bg-header-color: #64737d;
  --bg-menu-color: #273c6287;
  --bg-opacity-color: rgb(34 44 64 / 75%);
}

#root {
  background: #f9f9f9;
  height: 100%;
  font-family: "Gill Sans", sans-serif;
}

.ql-container.ql-disabled .ql-tooltip{
  display: none;
}

.ql-editor{
  padding: 12px 3px !important;
}

.height-0 {
  height: 0;
}

.w-100 {
  width: 100%;
}


#copy-text i{
  font-size: 20px;
  color: #64737d;
  cursor: pointer;
}
.no-pay-user{
  color: yellow;
  padding: 10px;
  font-size: 16px;
}

pre {
  white-space: pre-wrap;
  font-family: "Gill Sans", sans-serif !important;
  font-size: 15px !important;
  text-align: left;
}

.o-container {
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 35px;
  min-height: 60vh;
}

.struktura-form .input-group.flex-nowrap {
  border: 1px solid red;
  border-radius: 5px;
}

.form-check-input[type=checkbox]:checked {
  background-color: #1266f1 !important;
}

body {
  background: aliceblue;
}

button>a {
  color: unset;
}

input[type="file"] {
  display: none;
}

.f-italic {
  font-style: italic;
}

.start-test {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.start-test>button {
  width: 80%;
  text-align: center;
}

.doc-item {
  display: flex;
  align-items: center;
}

.doc-item button {
  margin-left: 15px;
  z-index: 1;
}

.doc-item iframe {
  display: none;
}

.input-group>.form-control {
  height: unset !important;
}

.main-container {
  margin: 0 auto;
  max-width: 1700px;
  padding-top: 25px;
}

#search-autocomplete {
  width: 80%;
}

.search-section {
  width: 300px;
  background-color: #64737d;
}

.search-section button {
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.search-result {
  background: #7b828c;
  position: absolute;
  z-index: 9;
  min-width: 200px;
  width: auto
}

.search-result>div {
  padding: 3px 7px;
  border-bottom: 1px solid rgb(155, 153, 153);
}

.search-result>div>a {
  color: white;

}

.search-result>div span {
  font-size: 15px;
  color: #d7d7d7;
}

.search-result>div:hover {
  cursor: pointer;
  background: #90969e;
}

.container-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  padding: 0;
}

.logo>img {
  width: 100px;
  margin-top: -15px;
  margin-bottom: -15px
}

footer {
  background-color: var(--bg-opacity-color) !important;
  opacity: .99;
  bottom: 0;
  position: relative;
  left: 0;
  width: 100%;
  display: flex;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: .5em 0;
  color: #fff;
  background-color: var(--bg-header-color);
  transition: transform 400ms cubic-bezier(.25, .37, .17, .96);
  -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);
}

.header .btn-ent {
  color: white;
  background-color: #2f2f2f;
}

.header .btn-ent:hover {
  color: white;
  background-color: #4b4747;
}

.header h1 {
  line-height: 1;
}

.header .login-btn {
  font-size: 10px;
  padding: 0px 8px;
  height: 22px;
}

.header[data-scroll="out"] {
  transform: translatey(-100%);
}

[data-scroll-dir-y="-1"] .header {
  transform: translatey(0);
}

.header-left-data {
  position: absolute;
  align-items: center;
  display: flex;
  padding: 10px;
  right: 0;
  top: 0;
}

.header-left-data .user-name {
  padding-right: 10px;
}

.header .container {
  display: flex;
  justify-content: space-between;
}

.header span {
  font-size: 12px;
}

.spiner {
  position: fixed;
  text-align: center;
  padding-top: 40%;
  top: 0;
  width: 100%;
  z-index: 7;
  height: 100%;
  background-color: rgb(83 87 112 / 44%);
}

.home {
  padding-top: 170px;
  min-height: 80vh
}

.home h1,
h2,
h3 {
  text-align: center;
  padding: 10px 20px;
}

.content {
  margin-top: 120px;
  min-height: 200px;
  padding-bottom: 100px;
}

@media screen and (max-width:576px) {
  .home {
    font-size: 0.9rem;
  }

  .search-section button {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .header a~span {
    display: none;
  }
}

.certificate {
  padding: 10px 0;
  text-align: center;
  display: flex;
  align-items: center !important;
  justify-content: space-around;
}

.paper {
  padding: 25px;
  margin: 0 100px;
  border: 1px solid #e9e9e9;
  box-shadow: 3px 3px 31px -19px rgba(0, 0, 0, 0.75);
  margin-bottom: 25px;
}

.paper .subtitle {
  font-style: italic;
  font-size: 14px;
  line-height: 1;
  padding-top: 5px;
  color: #939393;
}

.paper i {
  color: red;
  padding-right: 10px;
}

.paper i.clear {
  color: #1266f1 !important;
  padding: 5px;
  font-size: 20px;
  margin-top: -20px;
  cursor: pointer;
}

.sign-main {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

}

.sigCanvas {
  border: 1px dotted #b7b7b7;
  width: 600px;
  height: 200px;
  z-index: 999;
}

.required {
  background: #ff000012 !important;
}

.color-blue {
  color: #1266f1;
}