.certificate-mini .certificate-main {
  transform: scale(0.3);
}

.certificate-mini {
  position: relative;
  width: 300px;
  height: 300px;
  top: -251px;
  margin-top: -95px;
  z-index: 0;
}

.certificate-mini button {
  display: none;
}

.qr-code {
  position: absolute;
  top: 88px;
  left: 147px;
  width: 129px !important;
}

.table-certificate {
  position: absolute;
  right: 40px;
  top: 200px;
}

.td-table-certificate button {
  display: none;
}

.table-certificate .certificate-main {
  display: none;
}

.table-certificate .p-relative button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.table-cert {
  min-height: 500px;
}

.td-table-certificate:hover .table-certificate button {
  display: block !important;
}

.td-table-certificate:hover .certificate-main
{
display: block;
}

.table-certificate h1 {
  text-align: center;
}

.dev-title {
  color: #d70000;
}

.certificate-mini::after {
  content: 'ВЗІРЕЦЬ';
  position: absolute;
  top: 365px;
  left: 458px;
  transform: rotate(-30deg);
  font-size: 57px;
  color: #bfbfbf;
}

.certificate-main {
  width: 1152px;
  /* width: 1122px; */
  /* width: 100%; */
  position: relative;
  overflow: hidden;
  /* margin: 0 auto; */
  color: #837f7f;
  font-size: 13px;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.32);
}

.certificate-main img {
  width: 100%;
}

.certificate-title {
  top: 32%;
  text-align: center;
  font-size: 38px;
  position: absolute;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #515050;
  width: 1152px;
}

.certificate-title>div {
  margin-top: -15px;
  font-size: 18px;
}
.certificate-content h3{
  border-bottom: none !important;
}
.certificate-main .certificate-content h1 {
  top: 45%;
  font-size: 20px;
  position: absolute;
  margin: 0;
  padding: 0;
  font-weight: bold;
  width: 1152px;
}

.certificate-main .certificate-content h1>span {
  font-weight: normal;
  font-size: 15px;
  position: absolute;
  left: 220px;
  margin-top: 4px;
}

.certificate-main .certificate-content h2 {
  top: 51.5%;
  font-size: 18px;
  position: absolute;
  line-height: 0.9;
  padding: 0 150px;
  font-weight: bold;
  width: 1152px;
}

.course-text {
  top: 49%;
  font-size: 14px;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 1152px;
}

.certificate-main .num {
  top: 41%;
  font-size: 18px;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 1152px;
  font-weight: bold;
}

.certificate-main .num>span {
  font-size: 14px;
}

.certificate-main .duration-text {
  top: 58%;
  left: 65%;
  font-size: 14px;
  position: absolute;
  margin: 0;
  padding: 0;
}

.left-text {
  left: 10.2% !important;
}

.thema-text {
  top: 62%;
  left: 10.2%;
  font-size: 14px;
  position: absolute;
  margin: 0;
  padding: 0;
  text-align: left;
}

.certificate-main .bold {
  font-weight: 500;
}

.sign-1 {
  font-weight: bold;
  top: 75%;
  left: 10.2%;
  font-size: 13px;
  position: absolute;
  margin: 0;
  padding: 0;
  text-align: left;
}

.sign-2 {
  font-weight: bold;
  top: 83%;
  left: 10.2%;
  font-size: 13px;
  position: absolute;
  margin: 0;
  padding: 0;
  text-align: left;
}