.new-item {
    border: 4px solid white;
}

.new-item-title {
    text-align: center;
    font-weight: bold;
    background-color: white;
    padding: 5px 0;
}

.new-item-body {
    border-top: 4px solid white;
    padding: 5px 0;
    font-size: 13px;
    padding-left: 10px;
    margin-left: -4px;
}

.new-item-body button {
    margin: 10px auto;
    display: flex;
}

.new-item-body .sub-title {
    border-bottom: 1px solid #ededed;
    margin-bottom: 5px;
    width: 100%;
    color: #4f4f4f;
}

.new-item-name{
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    line-height: 1;
}

.new-item-body .sub-title pre {
    font-size: 12px !important;
}

.new-item-body a {
    display: flex;
    border: 1px solid transparent;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 5px;
    justify-content: space-between;


}

.nostyle {
    transform: none !important;
}

.new-item-body img {
    width: 100%;
}

.new-item-body a:hover {
    border: 1px solid #ededed;
    background-color: white;
}