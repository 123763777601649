.news-page .new-item-body .sub-title pre {
    font-size: 14px !important;
}

.news-page .new-item-body .sub-title.date {
    text-align: left;
}

.news-page .new-item-body {
    text-align: center;
    position: relative;
}

.news-page .new-item-body .edit-btn {
    position: absolute;
    right: 10px;
    top: 0;
}

.news-page .new-item-body .edit-btn>i:hover {
    color: #1266f1;
}

.news-page .new-item-body .edit-btn>i {
    cursor: pointer;
    font-size: 20px;
    padding: 0 5px;
}

.news-page .new-item-body img {
    width: 60%;
}

.modal-body-btn img{
    max-width: 700px;
}