.box {
    width: 80%;
    /* height: 20px; */
    border: 2px solid #000;
    margin: 20px 0;
    text-align: center;
    padding: 20px;
    font-weight: bold;
    border-radius: 10px;
    -webkit-box-shadow: 6px 6px 20px -16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 6px 20px -16px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 20px -16px rgba(0, 0, 0, 0.75);
}

.box-item {
    position: relative;
}

.box-item a {
    cursor: pointer;
}

.box.right {
    margin: 0px 0px 0px auto;
}

.info {
    background-color: #ddd;
    border-color: #aaa;
}

.warning {
    background-color: #FFF484;
    border-color: #DCC600;
}

.success {
    background-color: #dde3e7;
    border-color: #c7c7c7;
    color: #000d71;
}

.error {
    background-color: #EBB1B1;
    border-color: #973939;
    color: #973939;
}