.login-form {
  width: 900px;
  min-height: 93vh;
  background-color: white;
  padding: 20px 50px;
}

.nav-link{
  cursor: pointer;
}

.main-login-form {
  position: sticky;
  z-index: 9;
}

.form-outline .form-control.is-invalid~.form-label,
.was-validated .form-outline .form-control:invalid~.form-label {
  color: rgba(0, 0, 0, 0.448);
}

.close-btn {
  font-size: 33px;
  margin-left: 90%;
  color: #7b828c;
}

.close-btn:hover {
  color: #6e7072;
}

input {
  background-color: white !important;
}

.reg-tab {
  padding: 25px;
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 3px 3px 31px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 31px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 31px -19px rgba(0, 0, 0, 0.75);
}

.logo-background {
  max-width: 297px;
  opacity: 0.04;
  margin-top: -60px;
}

.z-10 {
  position: sticky;
  z-index: 10;
}

@media screen and (max-width: 550px) {
  .login-form {
    padding: 10px 0px;
  }

  .reg-tab {
    padding: 20px;
  }

  .reg-text {
    font-size: 12px;
  }
}