.navbar {
    background: #dde3e7;
    overflow: hidden;
    padding: 0px;
    margin-top: 6px;
    margin-bottom: -8px;
    width: 100%;
    height: 67px;
    display: flex;
    align-items: center;
    border-color: #080808;
    border-top: 1px solid black;
}

.navbar .logo {
    cursor: pointer;
    font-family: Lacquer;
    position: relative;
}

.navbar ul.links {
    margin-right: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    transition: 0.4s ease all, 0s ease background;
}

.navbar li {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    padding: 0 0px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease all, 0s ease margin;
    border-left: 1px solid #c5ccd1;
}

.navbar li:last-of-type {
    border-right: 1px solid #c5ccd1;
}

.navbar li:hover {
    background: #657187;
    cursor: pointer;
}

.navbar a {
    text-decoration: none;
    color: #080808;
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 1;
}

.navbar li:hover a {
    color: #fff;
}

.navbar.sticky {
    position: fixed;
    z-index: 40;
}

.navbar.sticky~main {
    position: relative;
    z-index: 20;
    top: 65px;
}

.navbar-brand {
    display: block;
    text-align: center;
    line-height: 1;
    color: black
}

.active-link {
    color: hsl(96, 82%, 35%) !important;
}

.dropdown-item.active {
    background-color: unset;
}

.dropdown-item:hover {
    background-color: #dde3e7;
    color: #080808 !important;

}

.nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
}

.user-name:hover .dropdown-menu {
    display: block !important;
}

.user-menu {
    top: 30px !important;
}

.user-name {
    cursor: pointer;
    position: relative;
    text-align: center;
}

.dropdown-menu {
    top: 160px;
    position: fixed;
    background-color: #9aa2b1;
    overflow-y: auto;
    font-size: 12px;
}

.dropdown-menu a {
    padding: 10px;
}
.dropdown-menu a:hover {
    background-color: #dde3e7;
    color: #657187 !important;
}

/* // RESPONSIVE */

@media screen and (max-width: 750px) {
    .navbar .right {
        height: 0;
        overflow: hidden;
    }

    .toggle {
        position: absolute;
        top: calc(65px / 2);
        right: 20px;
        z-index: 99;
        transform: translateY(-50%);
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .toggle>* {
        width: 80%;
        height: 3px;
        background: #fff;
        margin: 3px 0;
    }

    .navbar .logo {
        margin-top: 5px;
    }

    .navbar.collapsed .toggle .line1 {
        transform: rotate(-45deg) translate(-4px, 5px);
    }

    .navbar.collapsed .toggle .line2 {
        opacity: 0;
    }

    .navbar.collapsed .toggle .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }

    .navbar.collapsed .logo {
        position: absolute;
        z-index: 44;
    }

    .navbar .links {
        position: fixed;
        background: #080808;
        z-index: 40;
        top: 0;
        left: -100%;
        flex-direction: column;
        width: 45vw;
        height: 150vh;
    }

    .navbar li {
        border: none;
    }

    .navbar .links li:first-child {
        margin-top: 80px;
    }

    .navbar.collapsed .links {
        left: -30px;
        min-width: 270px;
    }

    .navbar.collapsed .links a {
        color: #fff;
    }

    .navbar.collapsed .links li {
        width: 100%;
    }

    .navbar.collapsed .links li:hover a {
        color: #080808;
    }

    .navbar.collapsed .right {
        position: fixed;
        width: 45vw;
        text-align: center;
        height: auto;
        bottom: 40px;
        left: 45vw/2;
        transform: translateX(-50%);
        z-index: 45;
    }

    .navbar.collapsed~main {
        filter: blur(1px);
        opacity: 0.8;
    }

    .navbar.sticky {
        position: fixed;
        z-index: 40;
    }

    .social-icons {
        margin-top: unset !important;
    }

    .dropdown-menu {
        top: 61px;
        left: 0;
        max-width: 70vh;
        position: absolute;
        overflow-x: auto;
    }

    .dropdown-menu a{
        width: fit-content;
        min-width: 200px;
    }



}

.social-icons {
    margin-top: -17px;
    padding: 0;
    padding-right: 20px;
    height: 100%;
}

.social-icons .btn {
    border-color: black;
    width: 2.0625rem;
    height: 2.0625rem;

    display: inline-flex;
    /* flex-wrap: nowrap; */
    align-items: center;
}

.social-icons .btn:hover {
    border-color: white;
    color: white !important;
}