.info-i{
    /* border-bottom: 1px solid rgb(155, 155, 155); */
    border-top: 4px solid white;
    /* margin: 20px 0; */
}



.info-i div{
    padding:  20px;
}

.info-i div>p{
   margin: 0;
}

.ql-align-center{
    text-align: center;
}

.info-i .edit-btn{
    float: right;

}

.info-i .edit-btn i{
    margin: 0 5px;
    cursor: pointer;
}

.info-i .edit-btn i:hover{
    color: blue;
}